<!--
 * @Author: wh
 * @Date: 2020-05-29 11:18:16
 * @LastEditTime: 2023-01-04 16:23:30
 * @LastEditors: Please set LastEditors
 * @Description: message senter index
 * @FilePath: \BusinessPlatform\src\views\messageCenter\pages\Default.vue
-->
<template>
    <div class="container">
        <div
            id="messagecentercontainer"
        >
            <!-- <header id="message_header">
                <p>
                    <span class="glyphicon glyphicon-cog"></span>消息中心
                </p>
            </header> -->
            <ul id="message_sidebar">
                <li
                    :class="{active:index==selectedSlide}"
                    v-for="(item, index) in sliderData"
                    :key="index"
                    @click="changeMenu(item,index)"
                >
                    <span class="icon_span" :style="`color: ${item.color};background: ${item.bgColor};`">
                        <span :class="item.className"></span>
                    </span>
                    <div class="ng-binding">
                        <p class="title">
                            {{ item.title }}
                        </p>
                        <p class="text_span">
                            {{ item.title }}
                        </p>
                    </div>
                    <i class="unread" v-if="item.unread>0">{{ item.unread }}</i>
                </li>
            </ul>
            <div id="message_content">
                <component :is="zjName" :msg-type="msgType"></component>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            selectedSlide: 0,
            panelObj: {},
            msgType: 'NIM',
        };
    },
    created() {
        this.panelObj = this.sliderData[0];
    },
    computed: {
        zjName: function () {
            let t_url = '';
            t_url = this.panelObj.componentName;
            const myComponent = () => import(`./page/${t_url}.vue`);
            // var myComponent = resolve => require.ensure([], () => resolve(require(`./../../components/custom_panel/${t_url}.vue`)));
            // var myComponent =require(`./../../components/custom_panel/${t_url}.vue`).default;
            // var app_url = {t_url : require(`./../../components/custom_panel/${t_url}.vue`).default};
            return myComponent;
        },
        sliderData() {
            const ptopUnread = window.$globalHub.$store.state.sessionUnread.ptopUnread;
            // const teamUnread = window.$globalHub.$store.state.sessionUnread.teamUnread;
            const sysUnreadCount = window.$globalHub.$store.state.sysUnread.sysMsgUnread;
            // const ccMsgUnreadCount = window.$globalHub.$store.state.sysUnread.ccMsgUnread;
            const backlogMsgUnreadCount = window.$globalHub.$store.state.sysUnread.backlogMsgUnread;
            const noticeMsgUnread = window.$globalHub.$store.state.sysUnread.noticeMsgUnread;
            const sysUpdateMsgUnRead = 0;
            const menuData = [
                {
                    id: '1003',
                    className: 'iconfont iconshezhi',
                    title: '系统消息',
                    text: '系统发布的消息',
                    componentName: 'systemMsg',
                    msgType: 1,
                    unread: sysUnreadCount,
                    color: '#0078FB',
                    bgColor: '#e5f1fe',
                },
                {
                    id: '1001',
                    className: 'iconfont iconminsuzhuguanli',
                    title: '对话',
                    text: '群聊、单聊',
                    componentName: 'NIM',
                    msgType: 0,
                    unread: ptopUnread,
                    color: '#ffffff',
                    bgColor: '#2CAF56',
                },
                // {
                //     id: '1002',
                //     className: 'iconfont icon05qunzufill',
                //     title: '群组',
                //     componentName: 'Team',
                //     msgType: 0,
                //     unread: teamUnread,
                // },
                // {
                //     id: '1003',
                //     className: 'iconfont iconchaosong',
                //     title: '抄送消息',
                //     componentName: 'otherMessage',
                //     msgType: 2,
                //     unread: ccMsgUnreadCount,
                // },
                {
                    id: '1004',
                    className: 'iconfont iconshijian1',
                    title: '待办事项',
                    text: '待办事项消息',
                    componentName: 'otherMessage',
                    msgType: 3,
                    unread: backlogMsgUnreadCount,
                    color: '#FF6E2A',
                    bgColor: '#fff0e9',
                },
                {
                    id: '1005',
                    className: 'iconfont icontixing',
                    title: '通知消息',
                    text: '通知消息内容',
                    componentName: 'noticeMessage',
                    msgType: 4,
                    unread: noticeMsgUnread,
                    color: '#04BA92',
                    bgColor: '#e5f8f4',
                },
                {
                    id: '1006',
                    className: 'iconfont iconxingzhuang',
                    title: '系统更新',
                    text: '系统更新消息',
                    componentName: 'sysUpdateMessage',
                    msgType: 0,
                    unread: sysUpdateMsgUnRead,
                    color: '#FF9101',
                    bgColor: '#fff4e5',
                },
            ];
            return menuData;
        },
    },
    methods: {
        changeMenu(item, index) {
            this.selectedSlide = index;
            this.panelObj = item;
            this.msgType = item.msgType;
        },
        getUnreadFun() {
            return this.$axios.get('/interfaceApi/message/MessageInfo/get_noread_count');
        },
    },
};
</script>
<style>
@import "./../css/default.component.css";
</style>
